import Image from "next/image"
import Link from "next/link"


function Notfound() {
  return (
    <div className="page_not_found header_gap">
       <div className="container">
            <div className="pnf_copy">
                <div className="page_title">
                    {/* <Image className="mb-4" src='/images/warning.png' width={150} height={150} alt="page not found"/> */}
                    <h1>404 PAGE NOT FOUND</h1>
                    <h4>The page you were looking for is not found.</h4>
                    <p>Please use our search engine or navigation above to find the page.</p>
                </div>
            </div>
       </div>
    </div>
  )
}

export default Notfound
